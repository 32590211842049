#navbar {
    display: flex;
    width: calc(100vw - 160px);
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100px;
    background-color: #121e37;
    padding: 0px 11%;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.linkNavBar {
    color: #E5E5E5;
    text-decoration: none;
    font-size: 24px;
}

#linksBarMobile {
    display: none;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 580px;
    overflow-x: hidden;
    margin-right: 320px;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #121e37);
}

.logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #121e37);
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 35s slide infinite linear;
}

.logos-slide img {
    height: 50px;
    margin: 0 40px;
}

#linksBar {
    display: flex;
    flex-direction: row;
    gap: 80px;
}

#copyButton {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

#hamburger {
    display: none;
}

@media screen and (max-width: 1600px) {
    .linkNavBar {
        font-size: 16px;
    }

    #navbar {
        height: 80px;
    }

    .logos-slide img {
        height: 30px;
        margin: 0px 20px;
    }

    .logos {
        margin-right: 15vw;
        width: 420px;
    }

}

@media screen and (max-width: 1280px) {
    #linksBar {
        gap: 50px;
    }
}

@media screen and (min-width: 2500px) {
    .logos {
        margin-right: 18vw;
    }
}

@media screen and (max-width: 1080px) {
    #linksBarMobileActive {
        position: fixed;
        top: 80px;
        left: 0px;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 40vh;
        justify-content: space-around;
        background-color: #121e37;
        z-index: 60;
    }

    .linkNavBar {
        font-size: 24px;
    }

  

    #linksBar {
        display: none;
    }

    .logos:before,
    .logos:after {
        position: absolute;
        top: 0;
        width: 60px;
        height: 100%;
        content: "";
        z-index: 2;
    }

    .logos {
        margin: 0;
        max-width: 75%;
    }

    #navbar {
        padding: 0px 2%;
        width: calc(100vw - 4%);
    }

    #hamburgerImg {
        height: 24px;
        padding: 0px calc(20px - 2vw);
    }

    #hamburger {
        height: 64px;
        background-color: transparent;
        border: none;
        display: unset;

    }

    #copyImg {
        height: 20px;
    }

    #overlay {
        width: 100vw;
        height: calc(60vh - 80px);
        position: fixed;
        left: 0px;
        bottom: 0px;
        backdrop-filter: blur(4px);
        z-index: 30;
    }

    #copyButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
}