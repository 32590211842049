* {
  margin: 0;
}

@font-face {
  font-family: 'RobotoBold';
  src: local('MyFont'), url(./roboto/Roboto-Bold.ttf)
}

@font-face {
  font-family: 'RobotoBlack';
  src: local('MyFont'), url(./roboto/Roboto-Black.ttf)
}

@font-face {
  font-family: 'PoppinsLight';
  src: local('MyFont'), url(./poppins/Poppins-Light.ttf)
}

@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(./poppins/Poppins-Regular.ttf)
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
}

.fullsize-image {
  max-width: 90%;
  max-height: 90%;
}

#crossOverlay{
  position: absolute;
  height: 30px;
  width: 30px;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

body{
  background-color: #14213D;
}