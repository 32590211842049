#overlayLanguagePopUp {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgb(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    backdrop-filter: blur(4px);
}

#languagePopUp {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    gap: 40px;
    display: flex;
    flex-direction: column;
}

.buttonPopUp {
    background-color: #FCA311;
    color: black;
    padding: 16px;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

#buttonsPopUpDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

#chooseLanguageP{
    font-size: 30px;
}

@media screen and (max-width: 1080px) {
    #languagePopUp {
    padding: 20px;
    }
    #chooseLanguageP{
        font-size: 26px;
    }
}