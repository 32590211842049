#languageActive {
    padding: 6px;
    background-color: transparent;
    border: 3px #FCA311 solid;
    color: #FCA311;
    width: 90px;
    font-family: sans-serif;
    border-radius: 6px;
    font-weight: bold;
}

#languageInactive {
    padding: 6px;
    background-color: transparent;
    border: 1px #ffffff solid;
    color: #ffffff;
    cursor: pointer;
    width: 90px;
    font-family: sans-serif;
    border-radius: 6px;

}

#languageSelectorMobile {
    display: flex;
    gap: 10px;
}

.languageSelectorDesktop {
    flex-direction: column;
    position: fixed;
    right: 20px;
    top: 12px;
    z-index: 900;
}
@media screen and (max-width: 1600px) {
    .languageSelectorDesktop {
        top: 4px;
    }
}
@media screen and (max-width: 1080px) {
    .languageSelectorDesktop {
        display: none !important;
    }
}