.projectHigh {
    background-color: #ffffff;

}

#hotP {
    color: white;
    background-color: red;
    border: 2px red solid;
    border-radius: 12px;
    padding: 6px;
    font-family: Poppins;
    font-weight: 700;
    font-size: 18px;
    margin-left: auto;
}