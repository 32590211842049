#portfolio {
    background-color: #14213D;
    padding: 100px 11%;
    overflow-x: hidden;

}

.whiteP {
    color: white;
}

.greyP {
    color: #E5E5E5;
}

#box1 {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 100px);
    align-items: center;
    justify-content: space-between;
}

#achilleP {
    font-size: 120px;
    font-family: RobotoBlack;
    font-weight: 700;
    margin-bottom: 40px;
}

.box1P {
    font-family: 'PoppinsLight';
    font-size: 30px;
}

#box1Text {
    display: flex;
    flex-direction: column;
}

#box2Text {
    display: flex;
    flex-direction: column;
    gap: 100px;
}


#box1About {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 60px;
}

#aboutP {
    width: 25vw;
    font-size: 22px;
    text-align: justify;
    font-family: 'PoppinsLight';
    line-height: 28px;
}

#moi {
    height: 40vh;
}

#arrow {
    transform: rotate(90deg);
    height: 40px;
    animation-duration: 2s;
    /* Durée de l'animation */
    animation-name: clignoter;
    /* Nom de l'animation */
    animation-iteration-count: infinite;
    /* Répétition infinie */
    position: absolute;
    top: 90vh;
    left: 11%;
}


@keyframes clignoter {
    0% {
        opacity: 1;
    }

    /* Opacité à 100% au début */
    40% {
        opacity: 0;
    }

    /* Opacité à 0% à 40% de l'animation */
    100% {
        opacity: 1;
    }

    /* Retour à 100% à la fin */
}

.none {
    display: none;
}

.githubProjectImg {
    height: 30px;
}

.titleProject {
    color: black;
    font-family: RobotoBold;
    font-size: 30px;
}

.linkProjectRight {
    color: black;
    font-family: PoppinsLight;
    order: 2;
}

.linkProjectLeft {
    color: black;
    font-family: PoppinsLight;
}

.boxProject {
    background-color: #FCA311;
    padding: 40px 60px;
    border-radius: 24px;
    width: min-content;
    display: flex;
    flex-direction: column;
    gap: 30px;

}

.boxProject1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
    width: min-content;
}

.boxProjectLeft {
    display: flex;
    flex-direction: column;
    width: 27vw;
    gap: 20px;
}

.projectDescription {
    color: black;
    font-family: PoppinsLight;
}

.screenshotProject {
    height: 300px;
    border-radius: 6px;
    order: 1;
    cursor: zoom-in;
}

.linksProjectLeft {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.linksProjectRight {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
}

#projects {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.Right {
    margin-left: auto;
    justify-content: flex-end;
}

.boxProjectRight {
    order: 2;
    display: flex;
    flex-direction: column;
    width: 27vw;
    gap: 20px;
}

.technoListLeft {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.technoListRight {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;

}

.technoP {
    font-family: Poppins;
    font-weight: 700;
    font-size: 18px;
    color: black;
    border: 1px black solid;
    padding: 6px;
    border-radius: 12px;
}


#resume {
    color: #FCA311;
    font-family: Poppins;
    margin-top: 20px;
}

#someOfMy {
    font-family: RobotoBlack;
    color: white;
    font-size: 40px;
}

.linksProjectMobile {
    display: none;
}

.calendly-badge-widget .calendly-badge-content {
    padding: 0 10px !important;
}

.actionButtonLanding:hover {
    background-color: #fdbf58 ;
}

.actionButtonLanding {
    padding: 8px 12px;
    background-color: #FCA311;
    color: black;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    text-decoration: none;
    font-family: Poppins;
    cursor: pointer;

}

#actionButtonsDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
}

@media screen and (max-width: 1600px) {
    #aboutP {
        font-size: 16px;
        line-height: 20px;
    }

    #achilleP {
        font-size: 90px;
    }

    #box1P {
        font-size: 24px;
    }

    #arrow {
        height: 30px;
    }

    .screenshotProject {
        height: 200px;
    }

    .titleProject {
        font-size: 20px;
    }

    .projectDescription {
        font-size: 12px;
    }

    .boxProject {
        padding: 30px 45px;
        gap: 20px;
    }

    .technoP {
        font-size: 14px;
    }

    .linkProjectLeft {
        font-size: 12px;
    }

    .linkProjectRight {
        font-size: 12px;
    }

    .githubProjectImg {
        height: 20px;
    }

    .boxProjectLeft {
        gap: 14px;
    }

    .boxProjectRight {
        gap: 14px;
    }
}

@media screen and (min-width: 2500px) {

    #achilleP {
        font-size: 160px;
    }

    #aboutP {
        font-size: 30px;
        line-height: 38px;

    }

    .box1P {
        font-size: 36px;
    }

    .screenshotProject {
        height: 500px;
    }

    .titleProject {
        font-size: 50px;
    }

    .projectDescription {
        font-size: 30px;
    }

    .technoP {
        font-size: 34px;
    }

    .linkProjectLeft {
        font-size: 32px;
    }

    .linkProjectRight {
        font-size: 32px;
    }

    .githubProjectImg {
        height: 50px;
    }

    .boxProjectLeft {
        gap: 30px;
    }

    .boxProjectRight {
        gap: 30px;
    }

    #projects {
        gap: 200px;
    }
}

@media screen and (max-width: 1080px) {
    #box1 {
        flex-direction: column;
        justify-content: unset;
        gap: 60px;
        height: unset;
    }

    #moi {
        height: 30vh;
    }

    .box1P {
        font-size: 20px;
    }

    #box1About {
        margin-right: 0px;
    }

    #resume {
        margin-top: 40px;
    }

    #aboutP {
        width: unset;
    }

    #box1Text {
        margin-top: 40px;
    }

    #portfolio {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 100px 5% 40px 5%;
    }

    #projects {
        padding-top: unset;
        gap: 40px;
    }

    .boxProject {
        width: calc(100% - 40px);
        padding: 20px 20px;
    }

    .boxProject1 {
        flex-direction: column;
        width: unset;
        gap: 20px;
    }

    .boxProjectLeft {
        width: 100%;
    }

    .boxProjectRight {
        width: 100%;
        order: 0;
    }

    .linksProjectRight,
    .linksProjectLeft {
        display: none;
    }

    .linksProjectMobile {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }

    .linkProjectRight {
        order: 0;
    }

    .technoListRight {
        justify-content: flex-start;
    }

    #arrow {
        display: none;
    }

    .screenshotProject {
        height: unset;
        width: 70%;
    }

    #jpmaImg {
        height: 260px;
        width: unset;
    }
}


@media screen and (max-width: 720px) {
    .screenshotProject {
        width: 100%;
    }
    .actionButtonLanding {
        padding: 8px 12px;
        background-color: #FCA311;
        color: black;
        border: none;
        border-radius: 10px;
        font-size: 14px;
        text-decoration: none;
        font-family: Poppins;
        cursor: pointer;
        width: fit-content;
    
    }
    
    #actionButtonsDiv {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }
    
}